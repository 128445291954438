import palette from 'src/theme/palette';
import {
    AppBar,
    Box,
    IconButton,
    Stack,
    Toolbar,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { PreviousIcon, NextIcon } from 'src/config/icons';

// ----------------------------------------------------------------------

export default function Pagination({
    paginationModel,
    setPaginationModel,
    paginationData,
    setPaginationData,
}) {
    return (
        <>
            <Stack direction="row-reverse" alignItems="center" spacing={{ xs: 1, sm: 1, md: 4 }}>
                <Stack sx={{ marginRight: 0 }}>
                    <IconButton
                        sx={{
                            color: 'black',
                            borderRadius: '5px',
                            px: 2,
                            backgroundColor: palette.secondary.main,
                            ':hover': {
                                backgroundColor: palette.secondary.dark,
                            },
                        }}
                        onClick={() => {
                            setPaginationModel({
                                ...paginationModel,
                                page: paginationModel.page + 1,
                            });
                            setPaginationData({
                                ...paginationData,
                                loading: true,
                            });
                        }}
                        disabled={paginationData.count != Number.MAX_SAFE_INTEGER}
                    >
                        <Icon icon={NextIcon} />
                    </IconButton>
                </Stack>

                <Stack>
                    <Typography>
                        Page{' '}
                        {`${paginationData.page} of ${
                            paginationData.count === Number.MAX_SAFE_INTEGER
                                ? 'many'
                                : paginationData.count + 1
                        }`}
                    </Typography>
                </Stack>

                <Stack>
                    <IconButton
                        sx={{
                            color: 'white',
                            borderRadius: '5px',
                            px: 2,
                            backgroundColor: palette.primary.main,
                            ':hover': {
                                backgroundColor: palette.primary.dark,
                            },
                        }}
                        onClick={() => {
                            setPaginationModel({
                                ...paginationModel,
                                page: paginationModel.page - 1,
                            });
                            setPaginationData({
                                ...paginationData,
                                loading: true,
                            });
                        }}
                        disabled={paginationData.page == 1}
                    >
                        <Icon icon={PreviousIcon} />
                    </IconButton>
                </Stack>
            </Stack>
        </>
    );
}
