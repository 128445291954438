import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import { Box, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import userService from 'src/services/UserServiceClass';
import { useParams } from 'react-router-dom';
import SquadTable from 'src/components/squadTable/squadTable';
import VerticalBarChart from 'src/components/VerticalBarChart';
import VerticalBarChartStacked from 'src/components/VerticalBarChartStacked';
import PerformanceLastTenGamesTable from 'src/components/performanceLastTenGamesTable/performanceLastTenGamesTable';
import SplineChartTeam from 'src/components/SplineChartTeam';
import { set } from 'lodash';
import { useLocation } from 'react-router-dom';

function TeamScout() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabFromQuery = queryParams.get('tab');
    console.log(tabFromQuery, 'tab query');
    const [activeTab, setActiveTab] = useState(tabFromQuery ? parseInt(tabFromQuery) : 5);
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    const { PlayerId } = useParams();
    const [loading, setLoading] = useState(false);
    const [teamsOne, setTeamsOne] = useState([]);
    const [teamsSquads1, setTeamsSquads1] = useState([]);
    const [teamsSquads2, setTeamsSquads2] = useState([]);
    const [teamsSeasonProgress, setTeamsSeasonProgress] = useState([]);
    const [teamsLastTen, setTeamsLastTen] = useState([]);
    const [turnArounds, setTurnArounds] = useState([]);
    const title = `Perofrmance`;
    const getCVGData = () => {
        userService
            .getTeamsOne(PlayerId)
            .then((response) => {
                setTeamsOne(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getTeamsSquads(PlayerId)
            .then((response) => {
                setTeamsSquads1(response);
                // if (response.length > 12) {
                //     setTeamsSquads1(response.slice(0, 12));
                //     setTeamsSquads2(response.slice(12));
                // }
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getTeamsSeasonProgress(PlayerId)
            .then((response) => {
                console.log(response);
                setTeamsSeasonProgress(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getTeamsLastTen(PlayerId)
            .then((response) => {
                setTeamsLastTen(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getTeamsTurnArounds(PlayerId)
            .then((response) => {
                setTurnArounds(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
    };
    useEffect(() => {
        setLoading(true);
        getCVGData();
    }, [PlayerId]);
    useEffect(() => {
        setLoading(true);
        if (Object.keys(teamsOne).length !== 0) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [teamsOne]);
    const data = {
        labels: teamsSeasonProgress.map((data) => data.label),
        datasets: [
            {
                // label: teamsSeasonProgress.map((data) => data.date),
                data: teamsSeasonProgress.map((data) => Math.floor(data.CVG)),
                fill: false,
                borderColor: '#00EFE0 ',
            },
        ],
    };
    const userData = {
        labels: ['0-15', '16-30', '31-45', '45+', '46-60', '61-75', '76-90', '90+'],
        datasets: [
            {
                barPercentage: 1.2,
                categoryPercentage: 0.5,
                label: 'Key Events Per Game',
                data: [9, 3, 2, 2, 12, 5, 9, 3],
                backgroundColor: [
                    '#00EFE0 ',
                    '#00EFE0 ',
                    '#00EFE0 ',
                    '#00EFE0 ',
                    '#00EFE0 ',
                    '#00EFE0 ',
                    '#00EFE0 ',
                    '#00EFE0 ',
                ],
            },
        ],
    };
    const percentageLabels = turnArounds.map((data, index) => data.win_rate);
    const userDataStacked = {
        labels: turnArounds.map((data, index) => data.label),
        datasets: [
            {
                barPercentage: 1.2,
                categoryPercentage: 0.5,
                label: 'CVG',
                data: turnArounds.map((data, index) => data.CVG),
                backgroundColor: function (context) {
                    return context.dataset.data[context.dataIndex] !== 0
                        ? '#00EFE0 '
                        : 'transparent';
                },
                borderWidth: 0,
                borderSkipped: 'true',
                // Custom data label configuration for 'Green' dataset
                datalabels: {
                    display: true,
                    color: 'black', // You can set the color as per your requirement
                    align: 'start',
                    anchor: 'end',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
            },
            {
                barPercentage: 1.2,
                categoryPercentage: 0.5,
                label: 'NEG',
                data: turnArounds.map((data, index) => data.negatives),
                backgroundColor: function (context) {
                    return context.dataset.data[context.dataIndex] !== 0
                        ? '#FF00FF '
                        : 'transparent';
                },
                borderWidth: 0,
                borderSkipped: 'true',
                // Custom data label configuration for 'Red' dataset
                datalabels: {
                    display: true,
                    // color: 'black', // You can set the color as per your requirement
                    align: 'start',
                    anchor: 'center',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                    color: (context) => {
                        // Get the dataset index and data index for the current label
                        const datasetIndex = context.datasetIndex;
                        const dataIndex = context.dataIndex;

                        // Get the value of the data label for the current dataset and data index
                        const value = context.chart.data.datasets[datasetIndex].data[dataIndex];

                        // Return black if the value is 0, otherwise return white
                        return value === 0 ? 'black' : 'white';
                    },
                },
            },
            {
                label: 'TopLabels',
                data: turnArounds.map((data, index) => data.matches),
                backgroundColor: 'transparent',
                datalabels: {
                    display: true,
                    color: (context) => {
                        // Get the dataset index and data index for the current label
                        const datasetIndex = context.datasetIndex;
                        const dataIndex = context.dataIndex;

                        // Get the value of the data label for the current dataset and data index
                        const value = context.chart.data.datasets[datasetIndex].data[dataIndex];

                        // Return black if the value is 0, otherwise return white
                        return value === 0 ? 'transparent' : 'black';
                    },
                    align: 'end',
                    anchor: 'start',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
            },
            {
                label: 'red',
                data: turnArounds.map((data, index) => data.CVG),
                backgroundColor: 'transparent',
                datalabels: {
                    color: 'white',
                    anchor: 'end',
                    align: 'end',
                    offset: function (context) {
                        let chartHeight = context.chart.height;
                        let scales = context.chart.scales;
                        let yAxisScale;
                        // Find the first y-axis scale
                        for (let scale in scales) {
                            if (scales[scale].axis === 'y') {
                                yAxisScale = scales[scale];
                                break;
                            }
                        }
                        if (!yAxisScale) return 0; // Return a default offset if no y-axis scale was found
                        let zeroPixel = yAxisScale.getPixelForValue(0);
                        let valuePixel = yAxisScale.getPixelForValue(
                            context.dataset.data[context.dataIndex],
                        );
                        let scaleSize = zeroPixel - valuePixel;
                        let scaleFactor = 0.1; // Modify this as needed
                        return chartHeight * scaleFactor * (scaleSize / chartHeight);
                    },
                    formatter: function (value, context) {
                        if (percentageLabels[context.dataIndex] === null) {
                            return 0 + '%';
                        }
                        return percentageLabels[context.dataIndex] + '%';
                    },
                    font: {
                        family: 'Poppins',
                        size: 14,
                    },
                },
            },
        ],
    };
    const screenScale = window.devicePixelRatio;
    let topValue;
    let gridsMarginTop;
    let tableWidth;
    let tabOneHeight;
    let tabSecondHeight;
    let tabThirdHeight;
    if (screenScale === 1) {
        topValue = '87vh';
        gridsMarginTop = '4vh';
        tableWidth = '69.2vw';
        tabOneHeight = '45vh';
        tabThirdHeight = '45vh';
        tabSecondHeight = '18vh';
    } else if (screenScale === 1.25) {
        topValue = '99vh';
        gridsMarginTop = '0vh';
        tableWidth = '70vw';
        tabOneHeight = '59vh';
        tabSecondHeight = '25vh';
        tabThirdHeight = '59vh';
    }
    const boxStyleRed = {
        width: '10px',
        height: '10px',
        background: 'linear-gradient(to right, #cc0000, #800000)', // light red to dark red
    };
    const boxStyleGreen = {
        width: '10px',
        height: '10px',
        background: 'linear-gradient(to right, #008000, #004d00)', // darker medium green to very dark green
    };
    const boxStyleYellow = {
        width: '10px',
        height: '10px',
        background: 'linear-gradient(to right, #cccc00, #808000)', // darker medium yellow to very dark yellow
    };
    const playerRankLetter = (rank) => {
        if (rank === 1) return 'st';
        else if (rank === 2) return 'nd';
        else if (rank === 3) return 'rd';
        else return 'th';
    };
    return (
        <Page title={title} style={{ marginTop: '4vh' }}>
            {' '}
            {loading ? (
                <Grid container alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontFamily: 'Poppins',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <div
                                style={{
                                    position: 'relative',
                                    paddingBottom: '65vh',
                                    overflow: 'hidden',
                                }}
                            >
                                {/* <img
                                    src="/datagen.png"
                                    alt="First Image"
                                    style={{
                                        position: 'absolute',
                                        top: '8vh',
                                        left: '2vh',
                                        width: '21vw',
                                        height: '42vh',
                                        objectFit: 'cover',
                                    }}
                                /> */}
                                <img
                                    src="/Shield_with_D_inside.png"
                                    alt="Second Image"
                                    style={{
                                        position: 'absolute',
                                        top: '0vh',
                                        left: '0vw',
                                        width: '40vw',
                                        height: '65vh',
                                        objectFit: 'cover',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10vh',
                                        left: '5vw',
                                        width: '30vw',
                                        height: '30vh',
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        gap: '0vw',
                                        marginLeft: '2vw',
                                        color: '#fff',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '0.5vh',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: '#00EFE0',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: '2.5vh',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        lineHeight: '2.5vh',
                                                        fontSize: '2.8vh',
                                                    }}
                                                >
                                                    {teamsOne?.CVG}
                                                </span>
                                                <span
                                                    style={{ lineHeight: '4vh', fontSize: '2vh' }}
                                                >
                                                    CVG
                                                </span>
                                            </div>
                                            <Tooltip
                                                title={
                                                    teamsOne.nationalTeamImage ??
                                                    teamsOne.countryImage
                                                }
                                            >
                                                <img
                                                    className="img1"
                                                    style={{ marginTop: '1.3vh' }}
                                                    src={
                                                        teamsOne.nationalTeamImage ??
                                                        teamsOne.countryImage
                                                    }
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={teamsOne.imageDataURL}
                                            alt="Avatar"
                                            style={{ height: '17vh', width: '9vw' }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '27.5vh',
                                        width: '24vw',
                                        height: '32vh',
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'center',
                                        color: '#ffc000',
                                    }}
                                >
                                    <h4 style={{ fontSize: '2.6vh' }}>{teamsOne.name}</h4>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '32vh',
                                        left: '0.3vw',
                                        right: '0vw',
                                        width: '17vw',
                                        height: '0.1vh',
                                        margin: '0vh 3.1vw',
                                        backgroundColor: 'white',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '34vh',
                                        left: '8.3vw',
                                        width: '32vw',
                                        height: '30vh',
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        gap: '1vw',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '1vh',
                                            fontSize: '2vh',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <div>Record</div>
                                        <div>Form</div>
                                        <div>Goals +</div>
                                        <div>Goals -</div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '1vh',
                                            fontSize: '2vh',
                                        }}
                                    >
                                        <div style={{ color: '#00EFE0' }}>
                                            {`${teamsOne.wins}W ${teamsOne.losses}L ${teamsOne.draws}D`}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '0.2vw',
                                                margin: '0.8vh 0vw',
                                            }}
                                        >
                                            {teamsOne?.form?.map((value, index) => {
                                                switch (value) {
                                                    case 0:
                                                        return (
                                                            <div key={index} style={boxStyleRed} />
                                                        );
                                                    case 1:
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={boxStyleGreen}
                                                            />
                                                        );
                                                    case 2:
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={boxStyleYellow}
                                                            />
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })}
                                        </div>
                                        <div style={{ color: '#00EFE0' }}>{teamsOne.goals}</div>
                                        <div style={{ color: '#00EFE0' }}>
                                            {teamsOne.goals_conceded}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '23.5vh',
                                        left: '4.7vw',
                                        width: '14vw',
                                        height: '65vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '3vh',
                                            color: 'black',
                                            fontWeight: 'bold',
                                            lineHeight: '2vh',
                                        }}
                                    >
                                        Rank
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '3vh',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {teamsOne.cvg_rank}
                                        <sup
                                            style={{
                                                fontSize: '1.5vh',
                                            }}
                                        >
                                            {playerRankLetter(teamsOne.cvg_rank)}
                                        </sup>
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={9}
                            style={{
                                marginTop: gridsMarginTop,
                                paddingLeft: '4vw',
                                paddingTop: '0vh',
                            }}
                        >
                            <div>
                                <div
                                    className="tab-buttons"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '2vw',
                                    }}
                                >
                                    <div onClick={() => handleTabClick(5)}>
                                        <Typography
                                            style={{
                                                margin: '1vh 0vw',
                                                color: activeTab === 5 ? '#00EFE0' : '',
                                                marginTop: '0vh',
                                                fontWeight: '500',
                                                fontSize: '2vh',
                                            }}
                                        >
                                            SEASON
                                        </Typography>
                                        {activeTab === 5 ? (
                                            <img src="/blue_swoosh.png" alt="Second Column Image" />
                                        ) : (
                                            <img src="/pink_swoosh.png" alt="Second Column Image" />
                                        )}
                                    </div>
                                    <div onClick={() => handleTabClick(1)}>
                                        <Typography
                                            style={{
                                                margin: '1vh 0vw',
                                                color: activeTab === 1 ? '#00EFE0' : '',
                                                marginTop: '0vh',
                                                fontWeight: '500',
                                                fontSize: '2vh',
                                            }}
                                        >
                                            SQUAD
                                        </Typography>
                                        {activeTab === 1 ? (
                                            <img src="/blue_swoosh.png" alt="Second Column Image" />
                                        ) : (
                                            <img src="/pink_swoosh.png" alt="Second Column Image" />
                                        )}
                                    </div>
                                    <div onClick={() => handleTabClick(2)}>
                                        <Typography
                                            style={{
                                                margin: '1vh 0vw',
                                                color: activeTab === 2 ? '#00EFE0' : '',
                                                marginTop: '0vh',
                                                fontWeight: '500',
                                                fontSize: '2vh',
                                            }}
                                        >
                                            GOALS REPORT
                                        </Typography>
                                        {activeTab === 2 ? (
                                            <img src="/blue_swoosh.png" alt="Second Column Image" />
                                        ) : (
                                            <img src="/pink_swoosh.png" alt="Second Column Image" />
                                        )}
                                    </div>
                                    <div onClick={() => handleTabClick(3)}>
                                        <Typography
                                            style={{
                                                margin: '1vh 0vw',
                                                color: activeTab === 3 ? '#00EFE0' : '',
                                                marginTop: '0vh',
                                                fontWeight: '500',
                                                fontSize: '2vh',
                                            }}
                                        >
                                            TURNAROUND
                                        </Typography>
                                        {activeTab === 3 ? (
                                            <img src="/blue_swoosh.png" alt="Second Column Image" />
                                        ) : (
                                            <img src="/pink_swoosh.png" alt="Second Column Image" />
                                        )}
                                    </div>
                                    <div onClick={() => handleTabClick(4)}>
                                        <Typography
                                            style={{
                                                margin: '1vh 0vw',
                                                color: activeTab === 4 ? '#00EFE0' : '',
                                                marginTop: '0vh',
                                                fontWeight: '500',
                                                fontSize: '2vh',
                                            }}
                                        >
                                            LAST 10 GAMES
                                        </Typography>
                                        {activeTab === 4 ? (
                                            <img src="/blue_swoosh.png" alt="Second Column Image" />
                                        ) : (
                                            <img src="/pink_swoosh.png" alt="Second Column Image" />
                                        )}
                                    </div>
                                </div>
                                <div className="tab-content">
                                    {activeTab === 5 && (
                                        <div>
                                            <Typography
                                                style={{ margin: '2vh 0vw', fontWeight: '500' }}
                                            >
                                                Season Progress
                                            </Typography>
                                            <div style={{ height: tabOneHeight, width: '65vw' }}>
                                                <SplineChartTeam
                                                    chartData={data}
                                                    teamsSeasonProgress={teamsSeasonProgress}
                                                />
                                            </div>
                                            <img
                                                src="/pink_swoosh_(long_right).png"
                                                alt="Second Column Image"
                                            />
                                        </div>
                                    )}
                                    {activeTab === 1 && (
                                        <div>
                                            <Typography
                                                style={{ margin: '2vh 0vw', fontWeight: '500' }}
                                            >
                                                Squad Overview
                                            </Typography>
                                            <div style={{ display: 'flex', gap: '5vw' }}>
                                                <div
                                                    style={{
                                                        height: tabOneHeight,
                                                        width: tableWidth,
                                                    }}
                                                >
                                                    <SquadTable props={teamsSquads1} />
                                                </div>
                                                {/* {teamsSquads2.length > 0 && (
                                                    <div
                                                        style={{
                                                            height: tabOneHeight,
                                                            width: tableWidth,
                                                        }}
                                                    >
                                                        <SquadTable props={teamsSquads2} />
                                                    </div>
                                                )} */}
                                            </div>
                                            <img
                                                src="/pink_swoosh_(long_right).png"
                                                alt="Second Column Image"
                                            />
                                            {/* <Typography style={{ margin: '1vh 0vw',fontWeight:'500'}}>
                                 Season Progress
                                </Typography>
                                <div style={{ height: '34vh', width: '65vw' }}>
                                    <SplineChartTeam chartData={data} />
                                </div> */}
                                        </div>
                                    )}
                                    {activeTab === 2 && (
                                        <div>
                                            <Typography
                                                style={{
                                                    // margin: '2vh 0vw',
                                                    marginTop: '2vh',
                                                    marginBottom: '2vh',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                Goals Scored
                                            </Typography>
                                            <div style={{ display: 'flex', gap: '5vw' }}>
                                                <div
                                                    style={{
                                                        height: tabSecondHeight,
                                                        width: '36vw',
                                                    }}
                                                >
                                                    <VerticalBarChart chartData={userData} />
                                                </div>
                                                <div
                                                    style={{
                                                        height: tabSecondHeight,
                                                        width: '36vw',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontWeight: '500',
                                                            color: '#00EFE0',
                                                        }}
                                                    >
                                                        Goals Scored
                                                    </Typography>
                                                    <Typography>
                                                        - 64% of goals scored are in the 2nd half
                                                    </Typography>
                                                    <Typography>
                                                        - Best scoring period is the 15 minutes
                                                        after half time (27%)
                                                    </Typography>
                                                    <Typography>
                                                        - 20% of goals scored are in the first 15
                                                        minutes
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div style={{ height: '1.4vh' }}>
                                                <img
                                                    src="/pink_swoosh_(long_left).png"
                                                    alt="Second Column Image"
                                                />
                                            </div>

                                            <Typography
                                                style={{
                                                    //  margin: '2vh 0vw'
                                                    marginTop: '2vh',
                                                    marginBottom: '2vh',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                Goals Conceded
                                            </Typography>
                                            <div style={{ display: 'flex', gap: '5vw' }}>
                                                <div
                                                    style={{
                                                        height: tabSecondHeight,
                                                        width: '36vw',
                                                    }}
                                                >
                                                    <VerticalBarChart chartData={userData} />
                                                </div>
                                                <div
                                                    style={{
                                                        height: tabSecondHeight,
                                                        width: '36vw',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontWeight: '500',
                                                            color: '#00EFE0',
                                                        }}
                                                    >
                                                        Goals Scored
                                                    </Typography>
                                                    <Typography>
                                                        - 64% of goals scored are in the 2nd half
                                                    </Typography>
                                                    <Typography>
                                                        - Best scoring period is the 15 minutes
                                                        after half time (27%)
                                                    </Typography>
                                                    <Typography>
                                                        - 20% of goals scored are in the first 15
                                                        minutes
                                                    </Typography>
                                                </div>
                                            </div>
                                            <img
                                                src="/pink_swoosh_(long_right).png"
                                                alt="Second Column Image"
                                            />
                                        </div>
                                    )}
                                    {activeTab === 3 && (
                                        <div>
                                            <Typography
                                                style={{ margin: '2vh 0vw', fontWeight: '500' }}
                                            >
                                                Turnaround Performances
                                            </Typography>
                                            <div style={{ height: tabThirdHeight, width: '60vw' }}>
                                                <VerticalBarChartStacked
                                                    chartData={userDataStacked}
                                                />
                                            </div>
                                            <img
                                                src="/pink_swoosh_(long_left).png"
                                                alt="Second Column Image"
                                            />
                                            {/* <Typography style={{ margin: '1vh 0vw',fontWeight:'500'}}>
                                 Season Progress
                                </Typography>
                                <div style={{height: '34vh', width: '65vw' }}>
                                    <SplineChartTeam chartData={data} />
                                </div> */}
                                        </div>
                                    )}
                                    {activeTab === 4 && (
                                        <div>
                                            <Typography
                                                style={{ margin: '2vh 0vw', fontWeight: '500' }}
                                            >
                                                Performances - Last 10 games
                                            </Typography>
                                            <div style={{ height: tabThirdHeight, width: '65vw' }}>
                                                <PerformanceLastTenGamesTable
                                                    props={teamsLastTen}
                                                />
                                            </div>
                                            <img
                                                src="/pink_swoosh_(long_left).png"
                                                alt="Second Column Image"
                                            />
                                            {/* <Typography style={{ margin: '1vh 0vw',fontWeight:'500'}}>
                                 Season Progress
                                </Typography>
                                <div style={{height: '34vh', width: '65vw' }}>
                                    <SplineChartTeam chartData={data} />
                                </div> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <div
                style={{
                    position: 'absolute',
                    top: topValue,
                    color: 'white',
                    left: '0vw',
                    right: '0vw',
                    width: '95.5vw',
                    height: '0.1vh',
                    margin: '0vh 2.15vw',
                    backgroundColor: 'rgba(255, 255, 255, 255)',
                }}
            />
        </Page>
    );
}

export default TeamScout;
