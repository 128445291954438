/*
  Imports
*/
import { Stack, Typography } from '@mui/material';
import LogoOnlyBody from 'src/components/misc/LogoOnlyBody';
import { SETTINGS } from 'src/config/settings';
import { RootStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import LoginForm from './forms/LoginForm';

/*
  Main Working
*/
export default ({ }) => {
    const loginDisplay = 'User';
    const image = SETTINGS.UserLoginImage;

    return (
        <RootStyle title={loginDisplay} sx={{
            // background: `url(background.jpeg)`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'auto',
        }}>
            <LogoOnlyBody image={image}>
                <Stack sx={{ mb: 6 }}>
                    {/* <Typography sx={{ color: palette.text.secondary }}>
                        Enter Your Login Details Below.
                    </Typography> */}
                </Stack>
                <LoginForm />
            </LogoOnlyBody>
        </RootStyle>
    );
};
