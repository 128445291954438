import React, { Component, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
function VerticalBarChartStacked({ chartData }) {
    let values = chartData.datasets[0].data;
    const ma = Math.max(...values);
    const max = Math.ceil(ma);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        console.log(windowWidth);
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const annotations = chartData.datasets[0].data.map((value, index) => {
        if (value === 0 || value === null) {
            return {
                type: 'point',
                xScaleID: 'x',
                yScaleID: 'y',
                xValue: index, // or replace with x-values if they aren't indexed
                yValue: 0,
                backgroundColor: 'transparent',
                borderColor: 'white',
                radius: 4
            };
        }
        return null;
    }).filter(Boolean);
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
      
        plugins: {
            tooltip: {
                enabled: true,
                filter: function(context) {
                    const label = context.dataset.label;
                    return ['CVG', 'NEG'].includes(label);
                }
            },
            annotation: {
                annotations: annotations
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked:'true',
                offset: true, 
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                border: {
                    color: 'rgba(0, 0, 0, 0)',  // Add this line
                },
                ticks: {
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: 14,
                    },
                },
            },

            y: {
                max: (max + 1) % 2 === 0 ? max + 1600 : max + 1600,
                beginAtZero: true,
                grid: {
                    display: true,
                    color: (context) =>{
                        const zeroLine= context.tick.value;
                        const gridColor= zeroLine === 0 ?'white':"rgba(0, 0, 0, 0)";
                        return gridColor;
                    },
                    lineWidth: 1, // Set the line width for y-axis grid lines
                    drawBorder: false, // Hide the y-axis grid line
                },
                border: {
                    color: 'white', // This will make the line at y axis transparent
                    display: false, 
                },  
                ticks: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                    mirror: false,
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                    labelOffset: windowWidth > 1294 ? -25 : -20,
                },
            },
        },
        barThickness: windowWidth > 1294 ? 60 : 20,
    };

    return (
     
            <Bar data={chartData} plugins={[ChartDataLabels, annotationPlugin]} options={options} />
       
    );
}

export default VerticalBarChartStacked;
