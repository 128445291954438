import React from 'react';
import userService from '../../../services/UserServiceClass';

export default function Auth({ rank, children, onAuth }) {
    const gotoLogin = () => {
        onAuth(false);
    };

    const loggedIn = (user) => {
        if (rank && rank > user.rank) return onAuth(false, true);

        onAuth(true);
    };

    const moveForward = () => {
        if (rank) {
            const user = userService.getLoggedInUser();
            if (user) return loggedIn(user);

            gotoLogin();
        }
        loggedIn();
    };

    React.useEffect(() => {
        userService.isLoggedIn().then(moveForward).catch(gotoLogin);
    }, []);
    return <>{children}</>;
}
