let BASIC_SETTINGS = {
    TITLE: 'Datagen Global',
    fullTitle: 'Datagen Global',
    tabTitle: 'Datagen Global’ Account',
    rowsPerPageList: [10, 30, 50, 100, 500],
    defaultPerPage: 10,
    // BackendURL: 'http://127.0.0.1:1337/', //Local
    BackendURL: 'https://api.datagen.global/', //Stagging

    InternetCheckInterval: 5 * 60 * 1000,

    LogoImage: '/static/logos/logo_2.png',
    LogoImage2: '/static/logos/logo_2.png',
    FaviconImage: '/static/logos/datagen_global_trans.png',
    DatagenGlobalBackgroundImage: '/static/illustrations/datagen-global.png',

    UserLoginImage: '/static/illustrations/user.png',
    ForgetPageImage: '/static/illustrations/forgot.png',
    LinkdinLogo: '/static/logos/R.png',
    DiscordLogo: '/static/logos/discord.png',

    Image404: '/static/illustrations/404.png',
    Image401: '/static/illustrations/401.png',
    DefaultAvatar: '/static/illustrations/avatar.webp',

    DefaultUploadedFileImage: '/static/icons/file.png',

    allowedExtensions: [
        'png',
        'jpg',
        'jpeg',
        'mp3',
        'pdf',
        'zip',
        'rar',
        'docx',
        'doc',
        'ppt',
        'pptx',
        'txt',
    ],

    acceptImageUpload: 'image/png, image/jpg, image/jpeg',
    acceptFileUpload:
        'image/png, image/jpg, image/jpeg, audio/mp3, application/pdf, application/zip, application/doc, application/docx, application/ppt, application/pptx, application/txt',
    maxUploadFileSize: 10 * 1024 * 1024, //10 Mbs

    //PALETTE

    PRIMARY: {
        lighter: '#ff32ff',
        light: '#ff7fff',
        lightest: '#ff4cff',
        main: '#FF00FF',
        dark: '#e500e5',
        darker: '#CC00CC',
        contrastText: '#fff',
    },

    SECONDARY: {
        lighter: '#33F2E6',
        light: '#19F0E3',
        main: '#00EFE0',
        dark: '#00D7C9',
        darker: '#00BFB3',
        contrastText: '#fff',
    },
};

BASIC_SETTINGS = {
    ...BASIC_SETTINGS,
    // ...SETTINGS_OVERRIDE, //Comment this in local testing
};

BASIC_SETTINGS.BackendURLAPI = BASIC_SETTINGS.BackendURL + 'api/';
BASIC_SETTINGS.ResetPageImage = BASIC_SETTINGS.ForgetPageImage;
BASIC_SETTINGS.DataReload = BASIC_SETTINGS.NotificationReload;

export const SETTINGS = BASIC_SETTINGS;
