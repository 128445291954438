import { Outlet, useNavigate } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { useState } from 'react';
import Auth from 'src/components/authentication/login/Auth';
import { RouteDefaultPage } from 'src/config/routes';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0),
    },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout({ rank, loginPage }) {
    const navigate = useNavigate();

    const [showPage, setShowPage] = useState(!loginPage);

    const handleOnAuth = (pass) => {
        if (!pass) return setShowPage(true);

        if (loginPage) return navigate(RouteDefaultPage);
    };
    const [open, setOpen] = useState(false);

    return (
        <Auth rank={rank} onAuth={handleOnAuth}>
            {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
            {/* <AuthSideBar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}

            {showPage && (
                <>
                    <br />

                    <br />

                    <HeaderStyle>
                        <Box
                            sx={{
                                display: { xs: 'block', md: 'none', lg: 'none' },
                            }}
                        >
                            {/* <Logo /> */}
                        </Box>
                    </HeaderStyle>
                    <br></br>
                    <Outlet />
                </>
            )}
            {/* <Footer /> */}
        </Auth>
    );
}
