/*
    Imports
*/
import {
    Alert,
    Box,
    Collapse,
    Grid,
    Stack,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    FormControl,
    Autocomplete,
    Avatar,
    Button,
    Divider,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, useRef } from 'react';
// import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from '@mui/lab';
import userService from 'src/services/UserServiceClass';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import { padding } from '@mui/system';
import Scrollbar from './Scrollbar';
import Label from './misc/Label';
import palette from 'src/theme/palette';
import { useNavigate } from 'react-router-dom';

/*
    Main Working
*/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            // marginTop: -55,
            // marginLeft: 250,
            // marginLeft: '15.5%',
            // marginTop: '-5.5%',
        },
    },

    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

const seasons = [2021, 2022];

const countries = [
    'International',
    'Argentina',
    'Australia',
    'Belgium',
    'Brasil',
    'Canada',
    'Croatia',
    'Denmark',
    'England',
    'France',
    'Germany',
    'Holland',
    'Italy',
    'Japan',
    'Korea',
    'Portugal',
    'Serbia',
    'Spain',
    'USA',
];

let competitions = {
    International: ["Women's Friendly"],
    Argentina: ['Prmiera B Nacional'],
    Brasil: ['Serie A', 'Serie B', 'CBF Brasileiro U20'],
    Denmark: ['U17 Division', 'U17 Ligaen', 'U19 Division', 'U19 Ligaen'],
    France: ['Ligue 1', 'Ligue 2', 'Championnat National U19'],
    Portugal: [
        'Primeira Liga',
        'Segunda Liga',
        'Liga Revelação Sub 23',
        'Taça da Liga',
        'Taça Revelação U23',
    ],
    Spain: ['LaLiga', 'Segunda División', 'Torcaf U16'],
    Germany: ['Bundesliga', '2. Bundesliga', 'U17 Bundesliga', 'U19 Bundesliga'],
    Italy: ['Serie A', 'Serie B', 'Campionato Primavera 1'],
    Holland: ['Eredivisie', 'Eerste Divisie'],
    Belgium: ['First Division A', 'First Division B'],
    England: ['Premier League', 'Championship'],
    Crotia: ['1. HNL', '2. HNL', 'Prva NL Srediste Kadeti U16'],
    Serbia: ['Superliga'],
    Japan: ['J1', 'J2', 'J3'],
    Korea: ['K League 1', 'K League 2'],
    Australia: ['A-League'],
    USA: ['MLS', 'USL Championship'],
    Canada: ['Canadian Premier League'],
};

const age = [
    { label: 'All', value: '0-100' },
    { label: '<19', value: '<19' },
    { label: '20-24', value: '20-24' },
    { label: '25-29', value: '25-29' },
    { label: '30+', value: '>30' },
];

const positions = [
    { Name: 'Goalkeeper (GK)', Abbreviation: 'GK' },
    { Name: 'Centre Back (CB)', Abbreviation: 'CB' },
    { Name: 'Left Back (LB)', Abbreviation: 'LB' },
    { Name: 'Right Back (RB)', Abbreviation: 'RB' },
    { Name: 'Defensive Midfield (DM)', Abbreviation: 'DM' },
    { Name: 'Centre Midfield (CM)', Abbreviation: 'CM' },
    { Name: 'Attacking Midfield (AM)', Abbreviation: 'AM' },
    { Name: 'Left Winger (LW)', Abbreviation: 'LW' },
    { Name: 'Right Winger (RW)', Abbreviation: 'RW' },
    { Name: 'Striker (ST)', Abbreviation: 'ST' },
];

export default function Compare({ setReload }) {
    /*
      States, Params, Navigation, Query.

*/
    const asd = localStorage.getItem('filters');
    const localStorageFilters = asd ? JSON.parse(asd) : null;
    const [serverError, setServerError] = useState('');
    const [players, setPlayers] = useState([]);
    const [search, setSearch] = useState(true);
    const [value, setValue] = useState('');
    const [test, setTest] = useState(null);
    const [playerId, setPlayerId] = useState(null);
    // const submitButtonRef = useRef(null);
    const navigation = useNavigate();
    const getData = () => {
        if (search) {
            return;
        }
        setSearch(true);
        userService
            .getSearchPlayers({ search: value })
            .then((response) => {
                setPlayers(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        // }
    };

    useEffect(() => {
        if (search) return;
        clearTimeout(test);
        setTest(setTimeout(getData, 1000));
    }, [value]);

    // useEffect(() => {
    //     submitButtonRef.current.click();
    // }, []);

    const handlePlayerChange = (_, value) => {
        if (value) {
            value.isTeam && navigation(`/teamScout/${value.teamId}`);
            setPlayerId(value);
            localStorage.setItem('playerId', value?.playerId);
            formik.handleSubmit();
        } else {
            setPlayerId(null);
        }
    };
    const compare = (values, setSubmitting) => {
        userService
            .compare(playerId?.playerId ?? 0, {
                filters: { ...values },
            })
            .then((response) => {
                for (let i = 0; i < response.length; i++) {
                    response[i].minutes = Math.floor(response[i].minutes);
                    response[i].CVG = Math.round(response[i].CVG);
                    response[i].rating = +response[i].rating;
                    response[i].scoring = +response[i].scoring;
                    response[i].creating = +response[i].creating;
                    response[i].defending = +response[i].defending;
                    response[i].intensity = +response[i].intensity;
                    response[i].winRate = +response[i].winRate;
                }
                localStorage.setItem('players', JSON.stringify(response));
                localStorage.setItem('pagesLoaded', 0);
                setReload(true);
            })
            .catch((error) => {
                console.log('Error', error, error.response);
                if (!error.response) {
                    setServerError('Error occured please try later');
                } else {
                    setServerError(error.response.data.msg);
                }
            })
            .finally(() => {
                if (setSubmitting) setSubmitting(false);
            });
    };
    const defaultFilters = {
        seasons: [],
        countries: [],
        competitions: [],
        positions: [],
        matches: 10,
        lastMatches: '',
        rating: 0,
        cvg: 0,
        ages: [],
    };
    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            seasons: localStorageFilters?.seasons || defaultFilters.seasons,
            countries: localStorageFilters?.countries || defaultFilters.countries,
            competitions: localStorageFilters?.competitions || defaultFilters.competitions,
            positions: localStorageFilters?.positions || defaultFilters.positions,
            matches: localStorageFilters?.matches || defaultFilters.matches,
            lastMatches: localStorageFilters?.lastMatches || defaultFilters.lastMatches,
            rating: localStorageFilters?.rating || defaultFilters.rating,
            cvg: localStorageFilters?.cvg || defaultFilters.cvg,
            ages: localStorageFilters?.ages || defaultFilters.ages,
        },
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            localStorage.setItem('filters', JSON.stringify(values));
            compare(values, setSubmitting);
        },
    });
    const {
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        resetForm,
        values,
    } = formik;
    /*
      Handlers
    */

    const handleDelete = (variable, value, x) => {
        let output = values[variable].filter((item) => item !== value);
        setFieldValue(variable, output);

        if (output.length === 0) {
            document.activeElement.blur();
        }
    };

    /*
      Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                margin="normal"
                style={{ height: '100%' }}
            >
                <Grid
                    container
                    style={{ height: '100%' }}
                    display="flex"
                    direction="column"
                    justifyContent={'space-between'}
                >
                    <Grid item>
                        <Stack sx={{ margin: 'auto', padding: 2, height: '100%' }} spacing={1}>
                            <Autocomplete
                                id="player-name"
                                options={players}
                                value={playerId}
                                getOptionLabel={(option) =>
                                    option.isTeam ? option.name : option.shortName || ' '
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.isTeam
                                        ? option.teamId === value.teamId
                                        : option.playerId === value.playerId
                                }
                                onChange={handlePlayerChange}
                                renderOption={(props, option, { inputValue }) => {
                                    return (
                                        <>
                                            <Stack>
                                                <Box
                                                    {...props}
                                                    component="li"
                                                    key={option.playerId ?? option.teamId}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box sx={{ width: 40, height: 40 }}>
                                                        <Avatar src={option.imageDataURL} />
                                                    </Box>
                                                    <Box sx={{ ml: 2 }}>
                                                        <Box sx={{ fontWeight: 'bold' }}>
                                                            {option.isTeam
                                                                ? option.name
                                                                : `${option.shortName}`}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                fontSize: 12,
                                                                color: '#87CEEB',
                                                            }}
                                                        >
                                                            {option.isTeam ? (
                                                                `${option.area} - ${option.gender}`
                                                            ) : (
                                                                <>
                                                                    {option.team} (
                                                                    {new Date(
                                                                        option.birthDate,
                                                                    ).getFullYear()}
                                                                    ) {option.birthArea}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Player/Team"
                                        onChange={(event) => {
                                            setValue(event.target.value);
                                            setSearch(false);
                                        }}
                                    />
                                )}
                                MenuProps={MenuProps}
                            />
                            <Divider />
                            <FormControl>
                                <InputLabel id="seasons-multiple-chip-label">Season</InputLabel>
                                <Select
                                    labelId="seasons-multiple-chip-label"
                                    id="seasons-multiple-chip"
                                    {...getFieldProps('seasons')}
                                    multiple
                                    fullWidth
                                    input={
                                        <OutlinedInput
                                            id="seasons-select-multiple-chip"
                                            label="Season"
                                        />
                                    }
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={(x) =>
                                                        handleDelete('seasons', value, x)
                                                    }
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {seasons.map((season) => (
                                        <MenuItem key={season} value={season}>
                                            {season}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="countries-multiple-chip-label">
                                    Countries
                                </InputLabel>
                                <Select
                                    labelId="countries-multiple-chip-label"
                                    id="countries-multiple-chip"
                                    {...getFieldProps('countries')}
                                    multiple
                                    fullWidth
                                    input={
                                        <OutlinedInput
                                            id="countries-select-multiple-chip"
                                            label="Countries"
                                        />
                                    }
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={() =>
                                                        handleDelete('countries', value)
                                                    }
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {countries.map((country) => (
                                        <MenuItem key={country} value={country}>
                                            {country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="competitions-multiple-chip-label">
                                    Competitions
                                </InputLabel>
                                <Select
                                    labelId="competitions-multiple-chip-label"
                                    id="competitions-multiple-chip"
                                    multiple
                                    fullWidth
                                    {...getFieldProps('competitions')}
                                    disabled={!values.countries || values.countries.length < 1}
                                    input={
                                        <OutlinedInput
                                            id="competitions-select-multiple-chip"
                                            label="Competitions"
                                        />
                                    }
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={() =>
                                                        handleDelete('competitions', value)
                                                    }
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {values.countries.map((country) =>
                                        competitions[country].map((competition) => (
                                            <MenuItem key={competition} value={competition}>
                                                {competition}
                                            </MenuItem>
                                        )),
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="positions-multiple-chip-label">
                                    Positions
                                </InputLabel>
                                <Select
                                    labelId="positions-multiple-chip-label"
                                    id="positions-multiple-chip"
                                    {...getFieldProps('positions')}
                                    multiple
                                    fullWidth
                                    input={
                                        <OutlinedInput
                                            id="positions-select-multiple-chip"
                                            label="Position"
                                        />
                                    }
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={() =>
                                                        handleDelete('positions', value)
                                                    }
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {positions.map((position) => (
                                        <MenuItem key={position} value={position.Abbreviation}>
                                            {position.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="age-range-label">Age Range</InputLabel>
                                <Select
                                    labelId="age-range-select-label"
                                    id="age-range-simple-select"
                                    {...getFieldProps('ages')}
                                    label="Age Range"
                                    multiple
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={age.find((a) => a.value === value).label}
                                                    onDelete={() => handleDelete('ages', value)}
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {age.map((age) => (
                                        <MenuItem key={age.value} value={age.value}>
                                            {age.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Box sx={{ padding: 1 }}>
                        <Typography id="cvg-slider" gutterBottom>
                            CVG
                        </Typography>
                        <Slider
                            {...getFieldProps('cvg')}
                            valueLabelDisplay="auto"
                            aria-labelledby="cvg-range-slider"
                            getAriaValueText={(value) => `${value}`}
                            min={0}
                            max={600}
                            marks={CVG}
                        />
                    </Box> */}

                            {/* <FormControl sx={{ paddingX: 1 }}>
                                <Label id="rating-slider" gutterBottom>
                                    CRE
                                </Label>
                                <Slider
                                    {...getFieldProps('rating')}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="rating-slider"
                                    getAriaValueText={(value) => `${value}`}
                                    min={0}
                                    max={15}
                                    marks={rating}
                                    size="small"
                                />
                            </FormControl> */}

                            <Grid item style={{ width: '100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            id="matches"
                                            {...getFieldProps('matches')}
                                            label="Min Games:"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            id="lastMatches"
                                            {...getFieldProps('lastMatches')}
                                            label="# Last Games:"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <br />
                        </Stack>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                        <Grid container sx={{ padding: 3, paddingLeft: 2, paddingRight: 2 }}>
                            <Grid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                                display={'flex'}
                                justifyContent={'flex-start'}
                            >
                                <LoadingButton
                                    style={{
                                        // padding: '0px 40px',
                                        width: '94.5%',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                    }}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    // disabled={!playerId}
                                    // ref={submitButtonRef}
                                    onSubmit={handleSubmit}
                                >
                                    APPLY
                                </LoadingButton>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <Button
                                    style={{
                                        // padding: '0px 40px',
                                        width: '94.5%',
                                        backgroundColor: palette.secondary.main,
                                        color: 'black',
                                        boxShadow: 'none',
                                        borderRadius: '5px',
                                    }}
                                    size="large"
                                    type="button"
                                    variant="contained"
                                    // color="#03AFF0"
                                    onClick={() => {
                                        resetForm();
                                        setPlayerId(null);
                                        localStorage.setItem(
                                            'filters',
                                            JSON.stringify(defaultFilters),
                                        );

                                        // localStorage.removeItem('filters');
                                        localStorage.removeItem('playerId');
                                        compare(defaultFilters);
                                        setReload(true);
                                    }}
                                >
                                    RESET
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {serverError && (
                    <>
                        <br />
                        <Stack marginTop={3}>
                            <Collapse in={!!serverError}>
                                <Alert severity="error">{serverError}</Alert>
                            </Collapse>
                        </Stack>
                    </>
                )}
            </Form>
        </FormikProvider>
    );
}
