import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { CardStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import { MHidden } from '../@material-extend';
import Logo from '../Logo';

export const Circle = styled('div')(({ theme }) => ({
    content: '',
    background: `linear-gradient(to left top, ${palette.background.paper}, ${palette.background.paper}, ${palette.primary.main}, ${palette.primary.main})`,
    // background: `url(static/illustrations/dots.png) repeat top left, linear-gradient(to left top, ${palette.background.paper}, ${palette.background.paper}, ${palette.primary.main}, ${palette.primary.main})`,
    // background: `url(static/illustrations/dots.png)`,
    borderRadius: '25%',
    height: '20%',
    width: '20%',
    position: 'absolute',
    top: -30,
    left: -30,
}));

export default ({ children, image, title }) => {
    /*
		Main Design
	*/

    return (
        <>
            <Container maxWidth="sm">
                {/* <img
                    src="background.jpeg"
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        objectFit: 'cover',
                        filter: 'brightness(0.6)',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        opacity: 0.1,
                    }}
                /> */}
                <MHidden width="mdDown">
                    <CardStyle sx={{ m: 'auto' }}>
                        {/* <Circle> */}
                        {/* <img src="static/illustrations/dots.png" alt="dots" /> */}
                        {/* </Circle> */}

                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            sx={{ mt: 3, mb: 0 }}
                            spacing={1}
                        >
                            <Grid item md={12}>
                                <Logo />
                            </Grid>
                            <Grid item md={12} sx={{ marginBottom: -1.5 }}>
                                <Typography variant="h3" gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                        {children}
                    </CardStyle>
                </MHidden>
                <MHidden width="mdUp">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        sx={{ mt: 3, mb: 5 }}
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <Logo />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: -1.5 }}>
                            <Typography variant="h3" gutterBottom>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    {children}
                </MHidden>
            </Container>
        </>
    );
};
