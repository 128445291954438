/*
  Imports
*/
import { Card, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
import Compare from 'src/components/Compare';
import palette from 'src/theme/palette';
import DataGrid from './tables/DataGrid';

/*
  Main Working
*/

export default () => {
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
        sort: 'CVG',
        order: 'DESC'
    });
    const [paginationData, setPaginationData] = useState({ loading: false, count: 1, page: 1 });
    const selectedPlayer = localStorage.getItem('playerId');

    const title = `Filter`;
    const screenScale = window.devicePixelRatio;
    let topMarginValue;
    let rightMarginValue;
    if (screenScale === 1) {
        topMarginValue = '4vh';
        rightMarginValue = '1.18vw';
    } else if (screenScale === 1.25) {
        topMarginValue = '0';
        rightMarginValue = '0.2vw';
    }
    return (
        <Page title={title} style={{ marginTop: topMarginValue }}>
            <Grid container spacing={3}>
                <Grid item md={2.5}>
                    <Card style={{ height: '100%' }}>
                        {/* <Typography variant="h5" sx={{ p: 2 }}>
              Filter
            </Typography>
            <Divider /> */}
                        <Compare setReload={setReload} />
                    </Card>
                </Grid>
                <Grid item md={9.5}>
                    <Card style={{ height: '100%' }}>
                        {/* <Box display="flex" justifyContent="flex-end">
                            <FormControlLabel
                                sx={{
                                    m: 2,
                                    p: 0,
                                    pl: 2,
                                    backgroundColor: palette.primary.main,
                                    color: 'white',
                                    borderRadius: '5px',
                                    ':hover': {
                                        backgroundColor: palette.primary.dark,
                                    },
                                }}
                                control={
                                    <Checkbox
                                        sx={{
                                            color: 'black',
                                            ml: 8,
                                        }}
                                    />
                                }
                                label="Compare Players"
                                labelPlacement="start"
                            />
                        </Box> */}

                        <DataGrid
                            reload={reload}
                            setReload={setReload}
                            paginationModel={paginationModel}
                            setPaginationModel={setPaginationModel}
                            setPaginationData={setPaginationData}
                        />
                    </Card>
                </Grid>

                <Grid
                    item
                    md={12}
                    sx={{
                        borderTop: '1px solid',
                        marginLeft: '2.15vw',
                        marginRight: rightMarginValue,
                    }}
                >
                    <Pagination
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        setReload={setReload}
                    />
                </Grid>
            </Grid>
        </Page>
    );
};
