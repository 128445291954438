import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './performanceLastTenGamesTable.css';
import Label from '../misc/Label';
import { set } from 'lodash';
export default function PerformanceLastTenGamesTable(props) {
    console.log(props.props);
    const screenScale = window.devicePixelRatio;
    let heightOfRow;
    if (screenScale === 1) {
        heightOfRow = '3vh'
    } else if (screenScale === 1.25) {
        heightOfRow = '5vh'
    }
    let background_Color = '';
    function formatDate(dateString) {
        // Step 1: Convert the given date string to a Date object
        const dateObj = new Date(dateString);
      
        // Step 2: Extract the year, month, and day from the Date object
        const year = dateObj.getUTCFullYear();
        const month = dateObj.getUTCMonth() + 1; // Months are zero-based, so add 1
        const day = dateObj.getUTCDate();
      
        // Step 3: Format the year, month, and day as a two-digit number
        const formattedYear = year.toString().substr(-2).padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');
      
        // Step 4: Concatenate the formatted values to get the desired format
        const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
      
        // Step 5: Return the formatted date
        return formattedDate;
      }
    return (
        <TableContainer
            component={Paper}
            className="TableContainer"
            sx={{
                height: '100%',
                width: '100%',
                fontFamily: 'Mont',
            }}
        >
            <Table
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                    },
                    fontFamily: 'Mont',
                    fontSize: '14px',
                    width: '100%',
                }}
            >
              
                <TableHead
                    sx={{
                        fontFamily: 'Mont',
                        fontSize: '14px',
                        borderBottom: '2px solid #00EFE0',
                    }}
                >
                    <TableRow
                        sx={{
                            fontFamily: 'Mont',
                            fontSize: '14px',
                        }}
                    >
                        <TableCell
                            align="left"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            Date
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            Round
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            Opponent
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            Home/Away
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            Score
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            Result
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            CVG
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont', fontSize: '14px', fontWeight: '600', paddingLeft: '4px',
                                paddingRight: '0px'
                            }}
                        >
                            NEG
                        </TableCell>
                      
                    </TableRow>
                </TableHead>
                <TableBody sx={{}}>
                    {props.props?.map((data, index) => {
                        let value = data.result;
                        if (value === 'L') {
                            value = 'error';
                        } else if (value === 'D') {
                            value = 'warning';
                        } else {
                            value = 'success';
                        }
                        background_Color = index % 2 === 0 ? '' : '#0C2F8A';
                        return (
                            <TableRow
                                key={data.type}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    {formatDate(data.dateutc)}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    {data.gameweek}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    {data.opponent}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    {data.isHome === true ? 'H' : 'A'}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    {data.margin}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    <Label color={value} variant="filled" sx={{
                                        width: '3vw', paddingBottom: '10px',
                                        paddingTop: '10px',
                                        paddingLeft: '18px',
                                        paddingRight: '18px',
                                        borderRadius: '4px',
                                    }}>
                                        {data.result}
                                    </Label>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    <Label
                                        variant="filled"
                                        sx={{
                                            color: 'black',
                                            backgroundColor: '#00EFE0',
                                            fontWeight: '400',
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.CVG && Math.floor(data.CVG)}
                                    </Label>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont', fontSize: '14px', fontWeight: '400', paddingLeft: '4px',
                                        paddingRight: '0px'
                                    }}
                                >
                                    {data.negatives}
                                </TableCell>
                               
                            </TableRow>
                        );
                    })}
                
                </TableBody>
                <br></br>
            </Table>
        </TableContainer>
    );
}
