/*
  Imports
*/
import MainPage from 'src/components/MainPage';
import Page from 'src/components/Page';

/*
  Main Working
*/

export default () => {
  return (
    <Page title="Datagen Global">
      <MainPage />
    </Page>
  );
};
