import React, { Component, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
function BarChart({ chartData, best, average }) {
    let values = chartData.datasets[0].data;
    const ma = Math.max(...values);
    const max = Math.ceil(ma);
    console.log(max);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        console.log(windowWidth);
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log(best);
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                font: {
                    family: 'Poppins',
                    size: '14',
                },
            },
            legend: {
                display: false,
            },
            annotation: {
                borderWidth: 2,
                annotations: [
                    {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: best,
                        borderColor: 'rgb(156,102,252)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                    {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: average,
                        borderColor: 'white',
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                ],
            },
        },
        scales: {
            x: {
                max: (max + 1) % 2 === 0 ? max + 1 : max + 2,
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                ticks: {
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: 14,
                    },
                },
            },

            y: {
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                ticks: {
                    mirror: true,
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                    labelOffset: windowWidth > 1294 ? -25 : -20,
                    color: 'white',
                },
            },
        },
        barThickness: windowWidth > 1294 ? 30 : 20,
    };

    return (
        <div
            style={{
                marginLeft: '10px',
                height: '34vh',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        color: 'rgb(251,239,60)',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginTop: '1%',
                    }}
                >
                    Key Events Per Game
                </span>
                <div
                    style={{
                        display: 'flex',
                        gap: '30px',
                        marginRight: '2%',
                        paddingTop: '1%',
                    }}
                >
                    <span
                        style={{
                            color: 'white',
                            fontSize: '14px',
                        }}
                    >
                        Player Average ---------
                    </span>
                    <span
                        style={{
                            color: 'rgb(156,102,252)',
                            fontSize: '14px',
                        }}
                    >
                        Best ---------
                    </span>
                </div>
            </div>
            <Bar data={chartData} plugins={[ChartDataLabels, annotationPlugin]} options={options} />
        </div>
    );
}

export default BarChart;
