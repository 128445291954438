import checkCircle from '@iconify/icons-ant-design/check-circle-filled';
import closeCircle from '@iconify/icons-ant-design/close-circle-filled';
import exclamationCircle from '@iconify/icons-ant-design/exclamation-circle-filled';
import lockOutlined from '@iconify/icons-ant-design/lock-outlined';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import roundFileMove from '@iconify/icons-ic/round-drive-file-move';
import password1Icon from '@iconify/icons-wpf/password1';

import patronIcon from '@iconify/icons-ant-design/customer-service-filled'; // Fawkes Games
import backIcon from '@iconify/icons-ic/baseline-arrow-back-ios'; // Fawkes Games
import discordIcon from '@iconify/icons-ic/baseline-discord'; // Fawkes Games
import homeIcon from '@iconify/icons-ic/baseline-home'; // Fawkes Games
import accountIcon from '@iconify/icons-ic/sharp-account-box'; // Fawkes Games
import transactionIcon from '@iconify/icons-icon-park-solid/transaction-order'; // Fawkes Games
import gemIcon from '@iconify/icons-la/gem-solid'; // Fawkes Games
import modifiedDate from '@iconify/icons-material-symbols/date-range-sharp'; // Fawkes Games
import redeemCode from '@iconify/icons-material-symbols/qr-code-scanner'; // Fawkes Games
import securityIcon from '@iconify/icons-material-symbols/security'; //Fawkes Games
import atIcon from '@iconify/icons-mdi/at'; // Fawkes Games
import migrationIcon from '@iconify/icons-mingcute/move-line'; // Fawkes Games
import logoutIcon from '@iconify/icons-oi/account-logout'; // Fawkes Games
import gamesIcon from '@iconify/icons-fluent/games-24-filled'; // Fawkes Games
import newsIcon from '@iconify/icons-jam/newspaper-f'; // Fawkes Games
import storeIcon from '@iconify/icons-ic/round-store'; // Fawkes Games

import exportIcon from '@iconify/icons-gg/import'; //Datagen Global
import previousIcon from '@iconify/icons-grommet-icons/form-previous';//Datagen Global
import nextIcon from '@iconify/icons-ic/sharp-navigate-next';//Datagen Global

export const LockIcon = lockOutlined;

export const SearchIcon = searchFill;
export const MoveIcon = roundFileMove;

export const SuccessDialogIcon = checkCircle;
export const ErrorDialogIcon = closeCircle;
export const WarningDialogIcon = exclamationCircle;

export const ShowPasswordIcon = eyeOffFill;
export const HidePasswordIcon = eyeFill;
export const PasswordIcon = password1Icon;
export const UsersIcon = peopleFill;

export const SecurityIcon = securityIcon;
export const PatronIcon = patronIcon;
export const AccountIcon = accountIcon;
export const MigrationIcon = migrationIcon;
export const RedeemCodeIcon = redeemCode;
export const TransactionIcon = transactionIcon;
export const DiscordIcon = discordIcon;
export const HomeIcon = homeIcon;
export const LogoutIcon = logoutIcon;
export const BackIcon = backIcon;
export const GemIcon = gemIcon;
export const EmailIcon = atIcon;
export const DateIcon = modifiedDate;
export const GamesIcon = gamesIcon;
export const NewsIcon = newsIcon;
export const StoreIcon = storeIcon;
export const ExportIcon = exportIcon;
export const PreviousIcon = previousIcon;
export const NextIcon = nextIcon