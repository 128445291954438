import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './SquadTable.css';
import Label from '../misc/Label';
import { set } from 'lodash';
export default function SquadTable(props) {
    const screenScale = window.devicePixelRatio;
    let heightOfRow;
    if (screenScale === 1) {
        heightOfRow = '3vh';
    } else if (screenScale === 1.25) {
        heightOfRow = '5vh';
    }
    const [totalMatches, setTotalMatches] = React.useState(0);
    let totalMat = 0;
    let totalGoal = 0;
    let totalAssist = 0;
    let totalMinutes = 0;
    let totalCVG = 0;
    let totalCVM = 0;
    let totalAvgKey = 0;
    let totalWin = 0;
    const [averageMinutes, setAverageMinutes] = React.useState(0);
    const [totalAssists, setTotalAssists] = React.useState(0);
    const [totalGoals, setTotalGoals] = React.useState(0);
    const [averageCVG, setAverageCVG] = React.useState(0);
    const [averageCVM, setAverageCVM] = React.useState(0);
    const [averageKey, setAverageKey] = React.useState(0);
    const [averageTotalWin, setAverageTotalWin] = React.useState(0);
    const [sortedData, setSortedData] = React.useState([]);
    const [isEven, setIsEven] = React.useState(false);

    return (
        <TableContainer
            component={Paper}
            className="TableContainer"
            sx={{
                height: '100%',
                width: '100%',
                fontFamily: 'Mont',
            }}
        >
            <Table
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                    },
                    fontFamily: 'Mont',
                    fontSize: '14px',
                    width: '100%',
                }}
            >
                <TableHead
                    sx={{
                        fontFamily: 'Mont',
                        fontSize: '14px',
                        position: 'sticky',
                        top: '0',
                        zIndex: '1',
                        boxShadow: '0px 2px 0px 0px #00EFE0',
                        backgroundColor: '#07092F',
                    }}
                >
                    <TableRow
                        sx={{
                            fontFamily: 'Mont',
                            fontSize: '14px',
                        }}
                    >
                        <TableCell
                            align="left"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            PLAYER
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            POS
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            MAT
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            MINS
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            GLS
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            AST
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            CVG
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            CVM
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            NEG
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            CRE
                        </TableCell>

                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            WIN %
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            AER %
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            TAC %
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            PASS %
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{}}>
                    {props?.props?.map((data, index) => {
                        console.log('Squat table data', data);
                        let value = data.rating && Math.round(data.rating * 10) / 10;
                        // if (value < 5) {
                        //     value = 'error';
                        // } else if (value >= 5 && value < 10) {
                        //     value = 'warning';
                        // } else {
                        value = 'success';
                        // }
                        return (
                            <TableRow
                                key={data.type}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.shortName}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.position.map((pos, index) =>
                                        index === data.position.length - 1 ? pos : pos + ',',
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.matches}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.minutes && Math.floor(data.minutes)}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.goals}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.assists}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    <Label
                                        variant="filled"
                                        sx={{
                                            color: 'black',
                                            backgroundColor: '#00EFE0',
                                            fontWeight: '400',
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.CVG && Math.floor(data.CVG)}
                                    </Label>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.CVM ?? 0}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    <Label
                                        variant="filled"
                                        color={'error'}
                                        sx={{
                                            color: 'white',
                                            fontWeight: '400',
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.negatives && parseInt(data.negatives, 10)}
                                    </Label>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    <Label
                                        color={value}
                                        variant="filled"
                                        sx={{
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.rating && Math.round(data.rating * 10) / 10}
                                    </Label>
                                </TableCell>

                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.winRate && Math.round(data.winRate * 10) / 10}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.aerRate && Math.round(data.aerRate * 10) / 10}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.defRate && Math.round(data.defRate * 10) / 10}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.passRate && Math.round(data.passRate * 10) / 10}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <br></br>
            </Table>
        </TableContainer>
    );
}
