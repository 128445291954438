import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './table.css';
import Label from '../misc/Label';
import { set } from 'lodash';
export default function BasicTable(props) {
    const screenScale = window.devicePixelRatio;
    let heightOfRow;
    if (screenScale === 1) {
        heightOfRow = '3vh';
    } else if (screenScale === 1.25) {
        heightOfRow = '5vh';
    }
    const [totalMatches, setTotalMatches] = React.useState(0);
    let totalMat = 0;
    let totalGoal = 0;
    let totalAssist = 0;
    let totalMinutes = 0;
    let totalCVG = 0;
    let totalCVM = 0;
    let totalAvgKey = 0;
    let totalWin = 0;
    let totalNegatives = 0;
    const [averageMinutes, setAverageMinutes] = React.useState(0);
    const [totalAssists, setTotalAssists] = React.useState(0);
    const [totalGoals, setTotalGoals] = React.useState(0);
    const [averageCVG, setAverageCVG] = React.useState(0);
    const [averageCVM, setAverageCVM] = React.useState(0);
    const [averageKey, setAverageKey] = React.useState(0);
    const [averageNegatives, setAverageNegatives] = React.useState(0);
    const [averageTotalWin, setAverageTotalWin] = React.useState(0);
    const [sortedData, setSortedData] = React.useState([]);
    const [isEven, setIsEven] = React.useState(false);
    React.useEffect(() => {
        if (props.props && props.props[1]) {
            const sortedData = props.props[1].slice().sort((a, b) => Number(b.CVG) - Number(a.CVG));
            setSortedData(sortedData);
            const isEven = sortedData.length % 2 === 0;
            setIsEven(isEven);
            // for (let i = 0; i < props.props[1].length; i++) {
            //     totalWin += parseFloat(props.props[1][i].winRate);
            //     totalAvgKey += parseFloat(props.props[1][i].rating);
            //     totalCVM += parseFloat(props.props[1][i].CVM);
            //     totalCVG += parseFloat(props.props[1][i].CVG);
            //     totalMinutes += parseFloat(props.props[1][i].minutes);
            //     totalMat += props.props[1][i].matches;
            //     totalGoal += parseInt(props.props[1][i].goals);
            //     totalAssist += parseInt(props.props[1][i].assists);
            //     totalNegatives += parseFloat(props.props[1][i].negatives);
            // }

            // setAverageNegatives(totalNegatives / props.props[1].length);
            // setAverageTotalWin(totalWin / props.props[1].length);
            // setAverageKey(totalAvgKey / props.props[1].length);
            // setAverageCVM(totalCVM / props.props[1].length);
            // setAverageCVG(totalCVG / props.props[1].length);
            // setAverageMinutes(totalMinutes / props.props[1].length);
            // setTotalMatches(totalMat);
            // setTotalGoals(totalGoal);
            // setTotalAssists(totalAssist);

            setAverageNegatives(parseFloat(props.props[0][0].negatives));
            setAverageTotalWin(parseFloat(props.props[0][0].winRate));
            setAverageKey(parseFloat(props.props[0][0].rating));
            setAverageCVM(parseFloat(props.props[0][0].CVM));
            setAverageCVG(parseFloat(props.props[0][0].CVG));
            setAverageMinutes(parseFloat(props.props[0][0].minutes));
            setTotalMatches(parseFloat(props.props[0][0].matches));
            setTotalGoals(parseFloat(props.props[0][0].goals));
            setTotalAssists(parseFloat(props.props[0][0].assists));

            // Do something with the calculated totals
        }
    }, [props.props]);

    let background_Color = '';
    return (
        <TableContainer
            component={Paper}
            className="TableContainer"
            sx={{
                height: '100%',
                width: '100%',
                fontFamily: 'Mont',
                // backgroundColor: '#203864',
            }}
        >
            <Table
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                    },
                    fontFamily: 'Mont',
                    fontSize: '14px',
                    width: '100%',
                }}
                style={
                    {
                        // marginLeft: '12px',
                        // marginRight: '7px',
                    }
                }
            >
                {/* <caption
                    style={{
                        captionSide: 'top',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: 'yellow',
                        fontFamily: 'Mont',
                        paddingLeft: '0px',
                        marginLeft: '0px',
                    }}
                >
                    Position Breakdown
                </caption> */}
                <TableHead
                    sx={{
                        fontFamily: 'Mont',
                        fontSize: '14px',
                        // backgroundColor: '#5F464D',
                        // backgroundColor: '#0C2F8A',
                        // borderTop: '2px solid orange',
                        borderBottom: '2px solid #00EFE0',
                    }}
                >
                    <TableRow
                        sx={{
                            fontFamily: 'Mont',
                            fontSize: '14px',
                        }}
                    >
                        <TableCell
                            align="left"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            POSITION
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            MAT
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            MINS
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            GLS.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            AST.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            CVG
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            CVM
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            NEG
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            CRE
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                fontWeight: '600',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                            }}
                        >
                            WIN %
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{}}>
                    {sortedData?.map((data, index) => {
                        let value = data.rating && Math.round(data.rating * 10) / 10;
                        // if (value < 5) {
                        //     value = 'error';
                        // } else if (value >= 5 && value < 10) {
                        //     value = 'warning';
                        // } else {
                        value = 'success';
                        // }
                        background_Color = index % 2 === 0 ? '' : '#0C2F8A';
                        return (
                            <TableRow
                                key={data.type}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    // backgroundColor: background_Color,
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                        // backgroundColor: '#5F464D',
                                    }}
                                >
                                    {data.type}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.matches}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.minutes && Math.floor(data.minutes)}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.goals}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.assists}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    <Label
                                        variant="filled"
                                        sx={{
                                            color: 'black',
                                            backgroundColor: '#00EFE0',
                                            fontWeight: '400',
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.CVG && Math.floor(data.CVG)}
                                    </Label>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.rating && Math.round(data.CVM * 10) / 10}%
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    <Label
                                        variant="filled"
                                        color={'error'}
                                        sx={{
                                            color: 'white',
                                            fontWeight: '400',
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.negatives && Math.floor(data.negatives)}
                                    </Label>
                                </TableCell>

                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    <Label
                                        color={value}
                                        variant="filled"
                                        sx={{
                                            width: '3vw',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            paddingLeft: '18px',
                                            paddingRight: '18px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {data.rating && Math.round(data.rating * 10) / 10}%
                                    </Label>
                                </TableCell>

                                <TableCell
                                    align="center"
                                    sx={{
                                        fontFamily: 'Mont',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        paddingLeft: '4px',
                                        paddingRight: '0px',
                                    }}
                                >
                                    {data.winRate}%
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {props?.props[0] && (
                        <TableRow
                            key={props?.props[0][0].type}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                fontFamily: 'Mont',
                                fontSize: '14px',
                                // backgroundColor: '#0C2F8A',
                                paddingLeft: '4px',
                                paddingRight: '0px',
                                borderTop: '2px solid #00EFE0',
                                borderBottom: '2px solid #00EFE0',
                            }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                    // backgroundColor: '#5F464D',
                                }}
                            >
                                Summary
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {totalMatches}
                                {/* {props?.props[0][0].matches} */}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {Math.floor(averageMinutes)}
                                {/* {props?.props[0][0].minutes &&
                                    Math.floor(props?.props[0][0].minutes)} */}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {totalGoals}
                                {/* {props?.props[0][0].goals} */}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {totalAssists}
                                {/* {props?.props[0][0].assists} */}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {Math.round(averageCVG)}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {Math.round(averageCVM * 10) / 10}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {Math.round(averageNegatives)}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {Math.round(averageKey * 10) / 10}
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontFamily: 'Mont',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }}
                            >
                                {Math.floor(averageTotalWin)}%{/* {props?.props[0][0].winRate}% */}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                <br></br>
            </Table>
        </TableContainer>
    );
}
