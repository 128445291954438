import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
function SplineChart({ chartData, CVG }) {
    const options = {
        maintainAspectRatio: false,
        tension: 0.4,
        elements: {
            point: {
                backgroundColor: 'white',
            },
        },
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                font: {
                    family: 'Poppins',
                    size: '14',
                },
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        const dataPoint = CVG[context[0].dataIndex];
                        const date = dataPoint.date.slice(0, 10);
                        const label = dataPoint.opponent;
                        const win_rate = dataPoint.score;
                        return `${label}\n${win_rate}\n${date}`;
                    },
                    label: function (context) {
                        return '';
                    },
                },
            },
        },
        layout: {
            padding: {
                // left: 50,   // Add padding to prevent cut-off on left
                // right: 50,  // Add padding to prevent cut-off on right
                top: 50, // Add padding to prevent cut-off at the top
                // bottom: 50  // Add padding to prevent cut-off at the bottom
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                border: {
                    color: 'white', // Add this line
                },
                ticks: {
                    autoSkip: false,
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
                offset: true,
            },

            y: {
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                border: {
                    color: 'rgba(0, 0, 0, 0)', // This will make the line at y axis transparent
                },
                ticks: {
                    display: false,
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
                offset: true,
            },
        },
    };

    return <Line data={chartData} plugins={[ChartDataLabels]} options={options} />;
}

export default SplineChart;
