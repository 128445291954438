import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
function RadarChart({ chartData }) {
    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                font: {
                    family: 'Poppins',
                    size: '14',
                },
                formatter: function (value, context) {
                    return value + '%'; // add percentage sign to value
                },
            },
            title: {
                display: true,
                text: "   KPI's",
                align: 'start',
                color: 'yellow',
                padding: 10,
                font: {
                    family: 'Poppins',
                    size: '14',
                },
            },
            legend: {
                display: false,
            },
        },

        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                grid: {
                    display: true,
                    color: 'rgba(255,255,255,0.1)',
                },
                ticks: {
                    display: false,
                },
                pointLabels: {
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
            },
        },
    };
    return (
        <div style={{ height: '40vh' }}>
            <Radar data={chartData} plugins={[ChartDataLabels]} options={options} />
        </div>
    );
}

export default RadarChart;
