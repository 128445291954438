/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { LoginSchema } from 'src/config/form-schemas';
import {
    HidePasswordIcon,
    PasswordIcon,
    ShowPasswordIcon,
    UsersIcon,
    EmailIcon,
} from 'src/config/icons';
import { RouteDefaultPage, RouteForgetPass, RouteLandingPage, RouteRegister } from 'src/config/routes';
import userService from 'src/services/UserServiceClass';
import palette from 'src/theme/palette';

/*
    Main Working
*/
export default ({ }) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            userService
                .login(values.email, values.password)
                .then((_data) => {
                    console.log('This worked fine');
                    navigate(location.state?.from ? location.state.from : RouteDefaultPage, {
                        replace: true,
                    });
                    //navigate(RouteDashboard);
                })
                .catch((err) => {
                    console.log('Error', err.response.data);
                    setSubmitting(false);
                    if (!err.response) {
                        setServerError('Error occured please try later');
                    } else {
                        setServerError(err.response.data.message);
                    }
                });
        },
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    /*
      Handlers
    */

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    /*
      Use Effect Hooks.
    */

    /*
      Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        type="email"
                        autoComplete="datagen-email"
                        label="Email"
                        {...getFieldProps('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={EmailIcon} inline="true" style={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <br />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                    {/* {!isSubmitting && (
                        <Link
                            component={RouterLink}
                            color={palette.error.light}
                            style={{ textDecoration: 'none', textAlign: 'right' }}
                            variant="subtitle2"
                            to={RouteForgetPass}
                        >
                            Password recovery
                        </Link>
                    )} */}
                </Stack>

                <br />
                <br />

                <Grid container spacing={{ md: 4 }} alignItems="center"
                    justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 20 }} alignItems="center"
                        justifyContent="center">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        // minHeight="100vh"
                        >
                            <LoadingFormButton
                                style={{ padding: '0px 70px' }}
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Login
                            </LoadingFormButton>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 20 }}>
                        <Button
                            style={{ padding: '0px 58px' }}
                            size="large"
                            type="submit"
                            variant="outlined"
                            loading={isSubmitting.toString()}
                            href={RouteRegister}
                        >
                            Register
                        </Button>
                    </Grid> */}
                </Grid>

                {serverError && (
                    <Stack sx={{}} marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
