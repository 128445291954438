import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/DashboardLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import { RouteDefaultPage, RouteLandingPage } from './config/routes';

import Login from './pages/Login';
import Logout from './pages/Logout';
import PageError from './pages/PageError';

import Chart from './pages/Chart';
import DatagenGlobal from './pages/DatagenGlobal';
import Find from './pages/Find';
import Performance from './pages/Performance';
import TeamScout from './pages/TeamScout';

export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [{ path: '', element: <DatagenGlobal /> }],
        },

        {
            path: '/login',
            element: <LogoOnlyLayout loginPage />,
            children: [{ path: '', element: <Login teacher /> }],
        },

        {
            path: 'find',
            element: <DashboardLayout />,
            children: [{ path: '', element: <Find /> }],
        },
        {
            path: 'chart/:PlayerId',
            element: <DashboardLayout />,
            children: [{ path: '', element: <Chart /> }],
        },
        {
            path: 'performance/:PlayerId',
            element: <DashboardLayout />,
            children: [{ path: '', element: <Performance /> }],
        },
        {
            path: 'teamScout/:PlayerId',
            element: <DashboardLayout />,
            children: [{ path: '', element: <TeamScout /> }],
        },
        {
            path: RouteLandingPage,
            element: <Navigate to={RouteDefaultPage} replace />,
        },
        {
            path: RouteLandingPage,
            element: <LogoOnlyLayout />,
            children: [
                { path: 'logout', element: <Logout /> },
                // { path: 'register', element: <Register /> },
                { path: '404', element: <PageError e404 /> },
                { path: '401', element: <PageError /> },
                // { path: '*', element: <Navigate to="/404" /> },
                // { path: 'reset.html', element: <ResetPassword /> },
                // { path: RouteForgetPass, element: <ForgetPassword /> },
                // { path: RouteResetPass, element: <ResetPassword /> },
            ],
        },

        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}
