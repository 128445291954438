import React, { Component, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
function VerticalBarChart({ chartData }) {
    let values = chartData.datasets[0].data;
    const ma = Math.max(...values);
    const max = Math.ceil(ma);
    console.log(max);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        console.log(windowWidth);
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                font: {
                    family: 'Poppins',
                    size: '14',
                },
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                // max: (max + 1) % 2 === 0 ? max + 5 : max + 5,
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                border: {
                    color: 'white', // Add this line
                },
                ticks: {
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: 14,
                    },
                },
            },

            y: {
                max: (max + 1) % 2 === 0 ? max + 5 : max + 5,
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                border: {
                    color: 'rgba(0, 0, 0, 0)', // This will make the line at y axis transparent
                },
                ticks: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                    mirror: true,
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                    labelOffset: windowWidth > 1294 ? -25 : -20,
                    color: 'white',
                },
            },
        },
        barThickness: windowWidth > 1294 ? 20 : 20,
    };

    return <Bar data={chartData} plugins={[ChartDataLabels, annotationPlugin]} options={options} />;
}

export default VerticalBarChart;
