import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

const StackedBarChart = ({ chartData, first, second, third }) => {
    console.log(first.cvg);
    console.log(second.cvg);
    console.log(third);
    const firstValue = first.cvg;
    const secondValue = second.cvg;
    const thirdValue = third.cvg;
    const options = {
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false,
                color: 'white',
                align: 'end',
                anchor: 'end',
                font: {
                    family: 'Poppins',
                    size: '14',
                },
                parseHTML: true,
                formatter: function (value, context) {
                    return '\u25C6 ' + value + '  name'; // add diamond symbol and value
                },
            },
            title: {
                display: true,
                text: 'Performance Scale',
                align: 'center',
                color: 'yellow',
                padding: 10,
                font: {
                    family: 'Poppins',
                    size: '14',
                },
            },
            legend: {
                display: false,
            },
            annotation: {
                // borderWidth: 2,
                annotations: [
                    {
                        type: 'line',
                        borderColor: 'green',
                        borderWidth: 3,
                        label: {
                            display: true,
                            backgroundColor: 'green',
                            borderRadius: 0,
                            color: 'white',
                            content: `${third.name},${Math.round(third.cvg * 10) / 10}`,
                            font: {
                                family: 'Poppins',
                                weight: 'bold',
                                size: 14,
                            },
                        },
                        scaleID: 'y',
                        value: thirdValue,
                    },
                    {
                        type: 'line',
                        borderColor: 'rgb(91,155,213)',
                        borderWidth: 3,
                        label: {
                            display: true,
                            backgroundColor: 'rgb(91,155,213)',
                            borderRadius: 0,
                            color: 'white',
                            content: `${first.name},${Math.round(first.cvg * 10) / 10}`,
                            font: {
                                family: 'Poppins',
                                weight: 'bold',
                                size: 14,
                            },
                        },

                        scaleID: 'y',
                        value: firstValue,
                    },
                    {
                        type: 'line',
                        borderColor: 'rgb(114, 123, 53)',
                        borderWidth: 3,
                        label: {
                            display: true,
                            backgroundColor: 'rgb(114, 123, 53)',
                            borderRadius: 0,
                            color: 'white',
                            content: `${second.name},${Math.round(second.cvg * 10) / 10}`,
                            font: {
                                family: 'Poppins',
                                weight: 'bold',
                                size: 14,
                            },
                        },

                        scaleID: 'y',
                        value: secondValue,
                    },
                ],
            },
        },
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'white',
                },
                stacked: true,
                display: true,
            },
            y: {
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                stacked: true,
                ticks: {
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
            },
        },
    };
    return <Bar data={chartData} plugins={[ChartDataLabels, annotationPlugin]} options={options} />;
};

export default StackedBarChart;
