import differenceInYears from 'date-fns/differenceInYears';
import * as Yup from 'yup';
import { asciiRegExp } from './regex';

export const ResetSchema = (password) =>
    Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be atleast 8 characters')
            .matches(asciiRegExp, 'Password must be in ASCII')
            .matches(/[a-z]/, 'Password must contain at least one lowercase character')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(
                /[!@#$%^&*()\-_"=+{}; :,<.>]/,
                'Password must contain at least one special character',
            ),
        confirm: Yup.string()
            .equals([password], 'Passwords do not match')
            .required('Confirm Password is required'),
    });

export const RegisterSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Enter correct email'),
    username: Yup.string()
        .required('Username is required')
        .min(7, 'Username must be atleast 7 characters')
        .max(13, 'Username must not be more than 13 characters')
        .matches(asciiRegExp, 'Username must be in ASCII'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be atleast 8 characters')
        .matches(asciiRegExp, 'Password must be in ASCII')
        .matches(/[a-z]/, 'Password must contain at least one lowercase character')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(
            /[!@#$%^&*()\-_"=+{}; :,<>]/,
            'Password must contain at least one special character',
        ),
    confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    dob: Yup.date()
        .required('Date of Birth is required')
        .test('dob', 'Should be greater than 13', function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 13;
        }),
    country: Yup.object().typeError('Please select a country').required('Country is required'),
});

export const ForgetSchema = (isEmail) => {
    if (!isEmail)
        return Yup.object().shape({
            username: Yup.string()
                .required('Username is required')
                .min(7, 'Username must be atleast 7 characters')
                .max(13, 'Username must not be more than 13 characters')
                .matches(asciiRegExp, 'Username must be in ASCII'),
        });

    return Yup.object().shape({
        username: Yup.string().required('Email is required').email('Enter correct email'),
    });
};
export const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Enter correct email'),
    password: Yup.string().required('Password is required'),
});

export const AccountSchema = Yup.object().shape({
    discord: Yup.string()
        .matches(/^.{3,32}#[0-9]{4}$/, 'Enter a valid Discord ID')
        .required('Discord is required'),
    country: Yup.object().typeError('Please select a country').required('Country is required'),
});
export const MigrationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
});
