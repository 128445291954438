export const RouteLandingPage = '/';
export const RouteForgetPass = '/recovery';
export const RouteResetPass = '/reset';
export const RouteLogin = '/login';
export const RouteDatagenGlobal = '/';

export const RouteGems = '/gems';
export const RouteAccounts = '/find';
export const Route401 = '/401';
export const RouteLogout = '/logout';


export const RouteDefaultPage = RouteAccounts;
