import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Box, Grid, Button } from '@mui/material';
import { SETTINGS } from 'src/config/settings';
import { MHidden } from './@material-extend';
import { RouteLogin } from 'src/config/routes';

const useStyles = makeStyles(() =>
    createStyles({
        bg: {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100%',
        },
    }),
);

export default function MainPage() {
    const classes = useStyles();
    let image = SETTINGS.DatagenGlobalBackgroundImage;

    return (
        <>
            <MHidden width="mdDown">
                <Box
                    className={classes.bg}
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                >
                    <Grid container direction="row">
                        <Grid
                            item
                            md={12}
                            style={{
                                marginTop: 20,
                                marginRight: 20,
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                style={{ padding: '0px 58px' }}
                                size="large"
                                type="submit"
                                variant="contained"
                                href={RouteLogin}
                            >
                                Members
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </MHidden>
            <MHidden width="mdUp">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item>
                        <Button
                            style={{ padding: '0px 58px' }}
                            size="large"
                            type="submit"
                            variant="contained"
                            href={RouteLogin}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </MHidden>
        </>
    );
}
